<template>
<div class="page-reporte-lista-precios">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Reporte de Inventario" class="elevation-1 px-5 py-3">

          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container grid-list-md>
                <v-row>
                  <v-col sm="4" md="4" lg="4">
                    <v-autocomplete v-model="model.id_sucursal" :items="sucursales" :hide-no-data="true"
                        :hide-selected="true" :loading="loading_sucursal" :rules="[rules.required]"
                        item-text="nombre" item-value="_id" label="Sucursal"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="4" md="4" lg="4">
                    <v-autocomplete v-model="model.categoria" :items="categorias" :hide-no-data="true" :hide-selected="true"                     
                    item-text="nombre" multiple chips small-chips deletable-chips item-value="nombre" label="Categoría">
                    </v-autocomplete>
                  </v-col>
                  <v-col sm="4" md="4" lg="4">
                    <v-autocomplete v-model="model.marca" :items="marcas" :loading="isLoadingMarca" multiple chips 
                      small-chips deletable-chips hide-no-data hide-selected 
                      item-text="nombre" item-value="nombre" label="Marca/Modelo" :hide-no-data="true" :hide-selected="true">
                      </v-autocomplete>
                  </v-col>
                  <v-col sm="4" md="4" lg="4">
                    <v-autocomplete v-model="model.id_articulo" :items="articulos" :hide-no-data="true" :loading="loadingArticulos"                       
                      ref="selectarticulo" placeholder="Ingrese nombre de artículo para buscar"
                      prepend-icon="search" clearable item-text="nombre" item-value="_id" label="Artículo">
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="4" md="4" lg="4">
                    <v-autocomplete v-model="model.id_proveedor" :items="proveedores" :hide-no-data="true" :loading="isLoadingProveedor"                       
                      ref="selectproveedor" placeholder="Ingrese nombre de proveedor para buscar"
                      prepend-icon="search" clearable item-text="nombre" item-value="_id" label="Proveedor">
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="model = clean_model()">
              <v-icon>cancel</v-icon> Limpiar Filtros
            </v-btn>
            <v-btn color="success" @click.native="generar_reporte()">
              <v-icon>done</v-icon> {{ "Generar Reporte" }}
            </v-btn>
          </v-card-actions>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
export default {
  components: {},
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },   
    
  },
  mounted: function() {
    if (!this.verificaPermiso('t.inventarios.inventario')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    this.getArticulos();
    this.getProveedores();
    this.getCategorias();
    this.get_sucursales();
    this.getMarcas();
  },
  data() {
    return {
      loading_sucursal:false,
      sucursales: [],
      search_articulo: '',
      search_proveedor: '',
      search_marca: null,
      categorias: [],
      marcas: [],
      articulos: [],
      proveedores: [],
      articulos_proveedores: [],
      fab: [],
      isLoading: false,
      isLoadingMarca: false,
      isLoadingProveedor: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Reporte de Inventario",
          disabled: true,
          href: ""
        }
      ],
      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: [],
      loadingArticulos:false,
    }
  },
  methods: {
    getArticulos:function(){
          this.loadingArticulos = true;
          let data = {
              "selector": {
                  "type": "articulos",
                  "estatus":"Activo",
                  //"proveedores.0": { "$exists": true }
              },
              "fields":["_id","nombre"],
              "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
          };
          window.axios
              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
              .then(response => {
                  response.data.docs.sort(this.ordenar_nombre);
                  this.articulos = response.data.docs;
              })
              .catch(error => {
                  this.$swal({
                      type: "error",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al obtener los articulos.",
                      footer: ""
                  });
              }).then(() => {
                  this.loadingArticulos = false;
              });
      },
      getProveedores() {
            this.isLoadingProveedor = true;
            let data = {
                "selector": {
                    "type": "proveedores",
                    "estatus":"Activo"
                },
                "fields":["_id", "nombre"],
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.proveedores = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los proveedores.",
                        footer: ""
                    });
                }).then(()=>{
                    this.isLoadingProveedor=false;
                });
        },
      ordenar_nombre: function( a, b ){
            if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
                return -1;
            }
            if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
                return 1;
            }
            return 0;
        },
    get_sucursales: function () {
        this.loading_sucursal = true;
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": {
                    "$eq": "Activo"
                }
            },
            "fields": [
                "nombre", "_id"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];
                if (response.data.docs.length > 0){
                    this.sucursales = response.data.docs;     
                    let todas = {
                        _id: '0',
                        nombre: 'TODAS'
                    };
                    this.sucursales.push(todas);               
                    this.model.id_sucursal = this.sucursales[0]._id;
                }
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            }).then(()=>{
                this.loading_sucursal = false;
            });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    getCategorias: function() {
      let data = {
        "selector": {
          "type": "categorias",
          "estatus": "Activo"
        },
        "fields": [
          "nombre", "_id"
        ]
      };
      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.categorias = response.data.docs;
          else
            this.categorias = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las categorias.",
            footer: ""
          });
        });
    },
    getMarcas: function(el) {
      let data = {
        "selector": {
          "type":"marcas",
          "estatus": "Activo",
        },
        "fields": [
          "nombre", "_id"
        ]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.marcas = response.data.docs;
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las marcas/modelos.",
            footer: ""
          });
        });
    },
    clean_model: function() {
      return {
        id_sucursal:"",
        categoria: [],
        marca: [],
        id_articulo: '',
        id_proveedor: '',
      };
    },
    generar_reporte: function() {
      try {
        if(!this.$refs.form.validate()){return;}
        
        if(this.model.id_sucursal == "0"){ //Si selecciona sucursal TODAS, debe seleccionar al menos otro filtro
          if(this.model.categoria.length == 0 && this.model.marca.length == 0 && (this.model.id_articulo == null || this.model.id_articulo == '')
            && (this.model.id_proveedor == null || this.model.id_proveedor == '')){

            this.$swal({
              type: "info",
              title: "¡Seleccione Filtros!",
              text: "En Sucursal TODAS, debe seleccionar un filtro más.",
              footer: ""
            });
            return false;
          }
        }
        
        window.dialogLoader.show('Espere un momento por favor..');

        if (this.model.id_articulo == null)
          this.model.id_articulo = '';
        if (this.model.id_proveedor == null)
          this.model.id_proveedor = '';

        window.axios
          .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_inventario/", this.model, {            
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Allow": "POST",
            "cache-control": "no-cache",
            "responseType": "arraybuffer"
          })
          .then(response => {
            var bytes = response.data;
            let blob = new Blob([bytes], {
                type: response.headers["content-type"]
              }),
              url = window.URL.createObjectURL(blob);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "Rpt_Inventario_"+window.moment().format("DDMMYYYY")+".xlsx";
            a.click();
            a.parentNode.removeChild(a);
          })
          .catch(error => {
            console.log(error);
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      } catch (error) {
        console.log("ERRORS: ", error);
      }
    },



  }
}
</script>
